import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

interface HeroData {
  heroImage?: any
}

const Hero = ({ heroImage }: HeroData) => {
  const image: any = getImage(heroImage?.node?.localFile)
  const altText: string = heroImage?.node?.altText || 'featured image'

  if (typeof heroImage === 'object') {
    return (
      <div>
        <div className="Hero mb-20">{image && <GatsbyImage image={image} alt={altText} />}</div>
      </div>
    )
  } else {
    return heroImage ? (
      <div>
        <div className="Hero mb-20 h-heromob md:h-heroimg">
          <div className="w-full h-heromob md:h-heroimg">
            <img
              className="h-full w-full object-cover"
              src={heroImage}
              alt={'Kategoria'}
              style={{ maxWidth: '100%', display: 'block', position: 'static' }}
            />
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className="Hero mb-20"></div>
      </div>
    )
  }
}

export default Hero
