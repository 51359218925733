import React from 'react'

export interface ContainerSidebarProps {
  children: React.ReactNode
  className?: string
  classNameInner?: string
  sidebar?: React.ReactNode
  hideSideOnMobile?: boolean
}

const ContainerSidebar = ({
  children,
  className = '',
  classNameInner = '',
  sidebar,
  hideSideOnMobile = true,
}: ContainerSidebarProps) => {
  return (
    <div className={`w-full ${className}`}>
      <div className={`max-w-content w-full mx-auto px-4 sm:px-10 2xl:px-2 grid grid-cols-10 gap-10 ${classNameInner}`}>
        <div className="col-span-10 lg:col-span-7">{children}</div>
        <aside className={`col-span-10 lg:col-span-3 sidebar ${hideSideOnMobile && 'hidden'} lg:block`}>
          <div className="lg:border-l border-latte lg:pl-10">{sidebar}</div>
        </aside>
      </div>
    </div>
  )
}

export default ContainerSidebar
