import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import People from '@/components/People'
import MainHeading from '@/components/MainHeading'
import ContainerSidebar from '@/components/Layout/ContainerSidebar'
import FileRepeater from '@/components/FileRepeater'
import Seo from '@/components/Seo'
import { seotype } from '@/types.d'
interface Files {
  file: string
  file_explanation: string
  heading: string
  file_name: string
}

interface BoardData {
  data: {
    form: any
    page: {
      title?: string
      featuredImage: any
      people: any
      filesSubHeading: string
      filesHeading: string
      files: Array<Files>
      uri: string
      seo: seotype
      language: {
        slug: string
      }
      translations: []
    }
    mainMenu: any
    langData: any
    wp: any
  }
}

const BoardTemplate = ({ data }: BoardData) => {
  const { page } = data
  const { form } = data

  const { mainMenu } = data
  const { wp } = data
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  const firstPeopleGroups = page?.people.slice(0, 2)
  const lastPeopleGroups = page?.people.slice(2)

  return (
    <Layout featuredImage={page.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={page?.language?.slug}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <MainHeading>{page.title}</MainHeading>
      </Container>
      <ContainerSidebar
        sidebar={<FileRepeater files={page?.files} heading={page?.filesHeading} subHeading={page?.filesSubHeading} />}
      >
        {firstPeopleGroups && (
          <People className="grid-cols-1 sm:grid-cols-2 sm:gap-4 gap-y-10" people={firstPeopleGroups} />
        )}
      </ContainerSidebar>
      <Container>
        {lastPeopleGroups && (
          <People
            className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-4 lg:gap-x-16 sm:gap-y-4 gap-y-10"
            people={lastPeopleGroups}
          />
        )}
      </Container>
      <Container className="lg:hidden">
        {' '}
        <FileRepeater files={page?.files} heading={page?.filesHeading} subHeading={page?.filesSubHeading} />{' '}
      </Container>
    </Layout>
  )
}

export const BoardPageQuery = graphql`
  query BoardPageById($id: String, $menu: [WpMenuLocationEnum], $translang: String, $formNum: Int) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      filesHeading: gwFileRepeaterHeading
      filesSubHeading: gwFileRepeaterSubtitle
      ...seo
      files: gwFileRepeater {
        file
        file_explanation
        heading
        file_name
      }
      ...people
      ...featuredHeroImage
      ...langdata
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default BoardTemplate
