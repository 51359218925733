import React from 'react'
import Header from '@/components/Layout/Header'
import Footer from '@/components/Layout/Footer'
import Hero from '@/components/Hero'
import MultiSiteSelect from '@/components/Layout/MultiSiteSelect'

export interface MainLayoutProps {
  /** Page content */
  children: React.ReactNode
  featuredImage?: any
  nav?: any
  lang?: any
  form?: any
  showhero?: boolean
}

/** Component shares layout structure between pages. Pass common sections like header, footer and content container here and wrap page components with it */
const MainLayout = ({ children, featuredImage, nav, lang, form, showhero = true }: MainLayoutProps) => {
  return (
    <>
      <Header nav={nav} lang={lang} />
      {showhero && <Hero heroImage={featuredImage} />}
      <main id="main" className="w-full main-height">
        {children}
      </main>
      <MultiSiteSelect className="mt-14" />
      <Footer form={form} lang={lang} />
    </>
  )
}

MainLayout.defaultProps = {
  location: '/',
  lang: 'fi',
}

export default MainLayout
