import React from 'react'
import defaultimg from '../../images/kalevala-henkilokuva.png'
import Image from '@/components/Image'

interface People {
  image: string
  mail: string
  name: string
  phone: string
  position: string
  title: string
  outlink: string
  outlinktext: string
  outlinkst: string
  outlinktextst: string
}

interface Group {
  content: string
  heading: string
  subtitle: string
  members: Array<People>
}

interface PeopleData {
  people: Array<Group>
  className?: string
  showDefaultImage?: boolean
}

const People = ({ people, showDefaultImage = true, className = '' }: PeopleData) => {
  return (
    <div className="people my-10">
      {people &&
        people.map((group, index: number) => {
          return (
            <div className="my-10" key={index}>
              <div>
                {group.heading && <h2 className="mb-4">{group.heading}</h2>}
                {group.heading && <p className="mb-8">{group.content}</p>}
                {group.subtitle && <h3 className="mb-4 text-lg">{group.subtitle}</h3>}
              </div>
              <div className={`grid ${className}`}>
                {group.members &&
                  group.members.map((person, index: number) => {
                    return (
                      <div key={person.name + index} className="flex flex-col m-2">
                        {person.image ? (
                          <div className="rounded-full overflow-hidden w-person h-person relative">
                            <Image
                              className="inline h-full w-auto min-w-full mx-auto my-0 object-cover"
                              alt="Henkilö kuva"
                              image={person.image}
                            />
                          </div>
                        ) : (
                          showDefaultImage && (
                            <div className="rounded-full overflow-hidden w-person h-person relative">
                              <Image className="people-max-img-w" alt="Henkilö kuva" image={defaultimg} />
                            </div>
                          )
                        )}
                        <div className="mt-4 flex flex-col wordbreak">
                          {person.position && <p className="font-bold">{person.position}</p>}
                          <p>
                            {person.title} {person.name}
                          </p>
                          {person.phone && <a href={`tel:${person.phone.split(' ').join('')}`}>{person.phone}</a>}
                          {person.mail && (
                            <a
                              className="text-blue font-bold hover:text-hovbrown focus:text-hovbrown"
                              href={`mailto:${person.mail}`}
                            >
                              {person.mail}
                            </a>
                          )}
                          {person.outlink && (
                            <a
                              className="text-blue font-bold hover:text-hovbrown focus:text-hovbrown"
                              href={`${person.outlink}`}
                            >
                              {person.outlinktext}
                            </a>
                          )}
                          {person.outlinkst && (
                            <a
                              className="text-blue font-bold hover:text-hovbrown focus:text-hovbrown"
                              href={`${person.outlinkst}`}
                            >
                              {person.outlinktextst}
                            </a>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default People
