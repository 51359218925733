import React from 'react'

interface MainHeading {
  children?: string
  className?: string
}

const MainHeading = ({ children = '', className = '' }: MainHeading) => {
  return <h1 className={`text-4xl md:text-5xl break-words uppercase wordbreak ${className}`}>{children}</h1>
}
export default MainHeading
