import React from 'react'
import Download from '@/assets/Download'

interface gwFileRepeaterData {
  files?: any
  subHeading?: string
  heading?: string
}

const gwFileRepeater = ({ files, heading, subHeading }: gwFileRepeaterData) => {
  return (
    <div className="file-repeater">
      {heading && <h2 className="text-xl mb-4">{heading}</h2>}
      {subHeading && <p className="mb-4">{subHeading}</p>}
      {files &&
        files.map((file: any, index: number) => {
          return (
            <div key={index}>
              {file.heading && <h3 className="mb-4 mt-10">{file.heading}</h3>}
              {file.file && (
                <a
                  className="text-blue font-bold download-link flex items-center mb-4 hover:text-hovbrown focus:text-hovbrown"
                  href={file.file}
                  download
                >
                  {' '}
                  <Download /> {file.file_name}
                </a>
              )}
              {file.file_explanation && <p className="mb-4">{file.file_explanation}</p>}
            </div>
          )
        })}
    </div>
  )
}
export default gwFileRepeater
