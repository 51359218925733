import React from 'react'

const Download = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
      <path
        className="st0"
        d="M472.5,489.6H27.6c-9.8,0-17.7-7.9-17.7-17.7v-11.2c0-9.8,7.9-17.7,17.7-17.7h444.8c9.8,0,17.7,7.9,17.7,17.7
            v11.2C490.2,481.7,482.3,489.6,472.5,489.6z"
      />
      <path
        className="st0"
        d="M381.8,192.4L381.8,192.4c-9.1-9.1-23.9-9.1-33,0L272,269.2V33.7c0-12.9-10.5-23.3-23.3-23.3
            c-12.9,0-23.3,10.5-23.3,23.3v235.5l-76.8-76.8c-9.1-9.1-23.9-9.1-33,0h0c-9.1,9.1-9.1,23.9,0,33l116.6,116.6
            c0.5,0.5,1.1,1.1,1.7,1.5c0.3,0.2,0.6,0.4,0.8,0.6c0.3,0.2,0.7,0.5,1,0.7c0.3,0.2,0.7,0.4,1,0.6c0.3,0.2,0.6,0.4,0.9,0.6
            c0.3,0.2,0.7,0.3,1.1,0.5c0.3,0.2,0.7,0.3,1,0.5c0.3,0.1,0.7,0.3,1,0.4c0.4,0.1,0.7,0.3,1.1,0.4c0.3,0.1,0.7,0.2,1.1,0.3
            c0.4,0.1,0.8,0.2,1.2,0.3c0.4,0.1,0.8,0.1,1.2,0.2c0.3,0,0.7,0.1,1,0.1c1.5,0.2,3.1,0.2,4.6,0c0.3,0,0.7-0.1,1-0.1
            c0.4-0.1,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.2,1.2-0.3c0.4-0.1,0.7-0.2,1.1-0.3c0.4-0.1,0.7-0.3,1.1-0.4c0.3-0.1,0.7-0.2,1-0.4
            c0.3-0.1,0.7-0.3,1-0.5c0.4-0.2,0.7-0.3,1.1-0.5c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.7-0.4,1-0.6c0.3-0.2,0.7-0.5,1-0.7
            c0.3-0.2,0.6-0.4,0.8-0.6c0.6-0.5,1.2-1,1.7-1.5l116.6-116.6C390.9,216.3,390.9,201.5,381.8,192.4z"
      />
    </svg>
  )
}
export default Download
